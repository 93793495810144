.propertyListItem {
  .img {
    // .new {
    //   background: darken($primary-color, 0.3);
    //   color: white;
    //   border-radius: 100%;
    //   width: 60px;
    //   height: 60px;
    //   position: absolute;
    //   right: 5px;
    //   top: 5px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   font-size: 0.8rem;
    //   font-weight: 600;
    //   letter-spacing: -1px;
    // }

    .new,
    .sold,
    .leased,
    .underOffer {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100px;
    }
  }
}
