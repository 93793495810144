@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "_scss/_variables.scss";

$tileColor: $primary-color;
$tileBg: $primary-color;

.propertyGallery {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  @include media-breakpoint-up(md) {
    /*>=768*/
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    /*>=992*/
    grid-template-columns: repeat(3, 1fr);
  }
}
.propertyGalleryItem {
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
