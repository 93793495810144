@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "_scss/_variables.scss";
@import "../propertyCommon.scss";
$tileColor: $primary-color;
$tileBg: $primary-color;

.propertyList {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  @include media-breakpoint-up(md) {
    /*>=768*/
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    /*>=992*/
    grid-template-columns: repeat(3, 1fr);
  }
}
.propertyListItem {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    border-left: 1px solid $primary-color;
    border-bottom: 2px solid $primary-color;
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover,
    &:active {
      background-color: rgba($primary-color, 0.05);
      text-decoration: none;
      img {
        transform: scale(1.05);
      }
    }
  }

  .img {
    height: 0;
    padding-bottom: 60%;
    position: relative;
    overflow: hidden;
    > img {
      transition: all 0.5s;
      transform: scale(1);
      object-fit: cover;
      object-position: center;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    line-height: 1.2;
    font-size: 0.9rem;
    .title {
      font-size: 1rem;
      color: $tileColor;
      margin-bottom: 0.3rem;
      font-weight: 700;
    }
    .addr {
      margin-bottom: 0.3rem;
      flex-grow: 1;
      color: $font-color;
    }

    .price {
      margin-bottom: 0.3rem;
      font-weight: 700;
      color: $font-color;
    }

    // .openHome {
    //   font-weight: 700;
    //   margin-bottom: 0.3rem;
    //   color: white;
    //   background-color: $tileColor;
    //   padding: 0.2rem 0.5rem;
    //   border-radius: 6px;
    // }
  }

  .openHome {
    position: absolute;
    z-index: 1;
    background: #283537;
    font-weight: bold;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

$pyt-icon-size: 46px;
.pty-icons {
  font-size: 0.875rem;
  line-height: 28px;

  .bedroom,
  .bathroom,
  .parking {
    display: inline-block;
    margin-right: 12px;
    position: relative;
    width: $pyt-icon-size;
    height: $pyt-icon-size;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: $pyt-icon-size;
      height: $pyt-icon-size;
      content: "";
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 1;
    }

    b {
      color: white;
      position: absolute;
      bottom: 0;
      z-index: 2;
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    &.hide {
      visibility: hidden;
    }
  }

  .bedroom:before {
    background-image: url("/assets/icons/beds-cover.png");
  }

  .bathroom:before {
    background-image: url("/assets/icons/bathrooms-cover.png");
  }

  .parking:before {
    background-image: url("/assets/icons/cars-cover.png");
  }
}

.filters {
  display: flex;
  justify-content: flex-end;
}

.propertyList--wide {
  display: block;
  .slick-prev:before,
  .slick-next:before {
    color: $primary-color;
    display: none;
  }
}

.propertyListItem--wide {
  margin-bottom: 1.5rem;
  .headline {
    display: none;
    color: #283537;
    font-size: 1.05rem;
    margin: 1rem 0;
  }
  .desc {
    display: none;
    color: #283537;
    line-height: 1.3;
    margin: 1rem 0;

    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .agent {
    display: none;
  }

  .subImgs {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  .propertyList--wide {
    .slick-prev:before,
    .slick-next:before {
      display: block;
    }

    .slick-prev {
      left: -40px;
    }
    .slick-next {
      right: -40px;
    }
  }

  .propertyListItem--wide {
    background-color: white;

    > a {
      flex-direction: row;
    }
    &:after {
      display: none;
    }
    .img {
      flex: 0 0 40%;
      height: auto;
      padding-bottom: 0;
    }

    .details {
      display: block;
      min-height: 18rem;
      padding-left: 1.5rem;

      .title {
        font-size: 1.3rem;
        margin: 1rem 0;
      }
      .pty-icons {
        margin: 1rem 0;
      }
      .headline {
        display: block;
      }
      .desc {
        display: block;
      }
    }

    .agent {
      display: block;
      float: right;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      max-width: 200px;
      height: auto;
      &__box {
        display: flex;
      }
      &__photo {
        max-width: 50px;
        flex: 0 0 auto;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
          border-radius: 100%;
          border: solid 2px #ccc;
        }
      }
      &__details {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__name {
        font-weight: 600;
        margin-bottom: 2px;
      }
      &__position {
        color: #283537;
        font-size: 0.8rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .propertyListItem--wide {
    .img {
      flex: 0 0 35%;
    }
    .details {
      display: flex;
      flex-direction: row;
    }
    .subImgs {
      display: flex;
      flex-direction: column;
      flex: 0 0 25%;
      > div {
        margin: 0.6rem 0;
      }
    }
    .detailsOnly {
      flex: 1 1 auto;
      padding-left: 1rem;
    }
  }
}
