@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "_scss/_variables.scss";

.propertyViewHdr {
  background-color: #283537;
  color: white;
  padding: 0.1rem 0;
  .address {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.5;
  }

  .underOffer {
    display: inline-block;
    margin-left: 1rem;
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    background: #f7901d;
    padding: 0.1rem 0.5rem;
    border-radius: 0.2rem;
    vertical-align: middle;
  }
}

.propertyView {
  background-color: white;
  padding-top: 0.3rem;
  padding-bottom: 1.5rem;

  .sectionTitle {
    font-weight: 600;
  }

  hr {
    border-top: 1px solid #ddd;
  }

  .features {
    margin-top: 0.5rem;
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .agent {
    display: flex;
    margin-bottom: 1rem;
    .agentPhotoCol {
      flex: 0 0 30%;
      margin-right: 1rem;
    }
    .agentPhoto {
      img {
        max-width: 100%;
      }
    }

    .agentName {
      font-weight: bold;
      margin: 1rem 0;
    }

    word-break: break-all;
  }

  .desc {
    margin: 1.5rem 0;
  }

  .descText {
    max-height: 9em;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      bottom: -2em;
      right: 0;
      width: 100%;
      height: 4em;
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 50%);
    }

    &.showAll {
      max-height: none;
      white-space: pre-line;
    }
    &.showAll::after {
      display: none;
    }
  }

  .showMoreBtn {
    margin-top: 1em;
    position: relative;
    display: block;
  }

  .details {
  }

  .headline {
    font-size: 2.2rem;
    color: $primary-color;
    margin: 0.3rem 0;
    font-weight: $weight-bold;
    line-height: 1.2;
  }

  .short-desc {
    margin-bottom: 0.8rem;
    line-height: 1.5;
  }

  .price {
    margin-bottom: 0.3rem;
    font-weight: $weight-bold;
  }

  .inspections {
  }
  .inspection {
    margin: 0.3rem 0;
    padding: 0.1rem 0.5rem;
    border: solid #ccc 1px;
    border-radius: 10px;
  }
}
