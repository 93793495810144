@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "_scss/_variables.scss";

.search-panel-banner {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000066;
    z-index: 0;
    pointer-events: none;
  }
}
.searchBannerPanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  pointer-events: none;
  h2 {
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
  }
  p {
    color: white;
  }

  &__content {
    margin-top: 3rem;
    width: 70%;
    max-width: 750px;
  }
}

.searchInput {
  display: flex;
  margin: 0 -2rem;
  pointer-events: all;
  &__input {
    flex: 1 1 auto;
    input[type="text"] {
      width: 100%;
      appearance: none;
      border: none;
      outline: none;
      padding: 0.75rem;
    }
  }
  &__button {
    flex: 0 0 8em;
    color: white;
    cursor: pointer;
    background-color: $primary-color;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
