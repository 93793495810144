@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "_scss/_variables.scss";

.agentContact {
  span,
  a {
    margin-left: 0.5rem;
  }
}

.agentSection {
  margin: 2.5rem 0;
}
